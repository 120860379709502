.product-table td,
.product-table tr {
  border-bottom: 1px solid #eff2f5;
  padding: 10px !important;
}

.product-table thead tr th {
  border-bottom: 1px solid #eff2f5;
  font-weight: bold !important;
  padding: 10px !important;
}

.product-table tbody tr {
  border-bottom: 1px solid #eff2f5;
  border-top: 1px solid #eff2f5;
  padding: 10px !important;
}

.filter-input {
  width: 100px;
  padding: 6px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
}

.filter-input:focus {
  outline: none;
  border-color: #ffda3a;
}

