#kt_header {
  background-color: #f3e550;
}
.aside.aside-dark .aside-logo {
  background-color: #efdf33;
}
.aside.aside-dark {
  background-color: #3a4c58;
}
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #ffffff;
}
.aside-dark
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here)
  .menu-icon
  .svg-icon
  svg
  [fill]:not(.permanent):not(g),
.aside-dark
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  .svg-icon
  svg
  [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.aside-dark .menu .menu-item .menu-link .menu-title {
  color: #fff;
}
.aside-dark .menu .menu-item .menu-section {
  color: #c7c7c7 !important;
}

.btn.btn-primary {
  color: #3a4c58;
}
.btn.btn-primary:hover:not(.btn-active) {
  color: #fff;
  background-color: #3a4c58 !important;
}
.user-page .home-banner {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;

  h3 {
    font-size: 40px;
    z-index: 1;
    margin-bottom: 20px;
    color: #fff;
  }
  p {
    font-size: 18px;
    z-index: 1;
  }
}

.home-banner:after {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
}

.home-action {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.21);

  border-radius: 10px;
  img {
    border-radius: 10px 10px 0 0;
  }
  .content-action {
    border-radius: 0 0 10px 10px;
  }
}
.top-header {
  display: none;
}
[data-kt-aside-minimize='on'] {
  .top-header {
    display: block !important;
  }
}
.user-page .wrapper {
  padding-left: 0 !important;
}

.header-menu-tabs {
  .menu {
    flex-direction: row;
    justify-content: space-between;
    .menu-item {
      align-items: start;
      .menu-link {
        flex-direction: column;
        width: 100px;
        text-align: center;
        .tab-image {
          border-radius: 50%;
          margin-bottom: 10px;
          background: #133387;
        }
        &.active {
          .tab-image {
            background: #ffda3a;
            background-position: 0 -100px;
          }
          .menu-title {
            color: #5e6278;
            font-weight: bold;
          }
        }
        &:hover {
          .tab-image {
            background: #ffda3a;
            background-position: 0 -100px;
          }
          .menu-title {
            color: #5e6278 !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.tab-items-wrap {
  padding: 15px;
}
.order-list {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sub-total-amount {
    font-size: 20px;
    font-weight: bold;
  }
  h6 {
    color: #545353;
  }
  .btn {
    text-transform: uppercase;
  }
  .order-items {
    background: #fff;
    list-style-type: none;
    padding-left: 0;
    .order-item-wrap {
      padding: 10px;

      h3 {
        font-size: 14px;
      }
      .item-selection {
        font-size: 11px;
      }

      .item-total {
        font-weight: bold;
      }
    }
  }
}
.page-title {
  padding-top: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.item-row {
  background: #f5f5f5;
  padding: 20px 30px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  margin-bottom: 20px;
  .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .row-price {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .col-add-btn {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    .fa {
      color: #3a4c56;
    }
  }
  h4 {
    margin-bottom: 20px;
  }
}
