.lookup-table td,
.lookup-table tr {
  border-bottom: 1px solid #eff2f5 !important;
  padding: 20px !important;
}

.lookup-table thead tr th {
  font-weight: bold !important;
  padding: 20px !important;
}
