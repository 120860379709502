.summary-table td,
.summary-table tr {
  border-bottom: 1px solid #eff2f5 !important;
  padding: 10px !important;
}

.summary-table thead tr th {
  color: #fff;
  font-weight: bold !important;
  padding: 10px !important;
  background-color: black;
}
